"use strict";

import 'intersection-observer'
import 'lazysizes'

import barba from '@barba/core';
import { gsap } from "gsap"

import { ready } from "./tools/utils.js"
import scroll from "./tools/locomotiveScroll.js"

// import { sliders } from './atoms/sliders.js';
import { cookies } from "./atoms/cookies.js"
// import { formContact } from './forms/contact';

ready(function() {
  barba.init({
    debug: true,
    timeout: 5000,
    transitions: [
      /* START TRANSITION */
      {
        name: 'transtion',
        sync:true,
        once(data) {
        

          let done = this.async()

          let tl = gsap.timeline({
            onStart: function() {
              window.scrollTo(0, 0)
              document.getElementsByTagName("body")[0].classList.add("non-event")
            },
            onComplete: function() {
              done()
              document.getElementsByTagName("body")[0].classList.remove("non-event")
            }
          });

          tl
          .to(document.querySelectorAll('.intro-et'), {
            top: "0",
            duration: 0.6,
            ease: "power4.out"
          }, 0)
          .to(document.querySelectorAll('.intro-et'), {
            top: "-80px",
            duration: 1,
            ease: "power4.in"
          }, 1)
          .to(document.querySelectorAll('.anim-intro'), {
            display: "none",
            duration: 0,
          }, 1.8)
          .to(document.querySelectorAll('main .content'), {
            top: "0vw",
            opacity: 0,
            duration: 0,
            ease: "power4.out"
          }, 0)
          .to(document.querySelectorAll('main .content'), {
            top: "0vw",
            opacity: 1,
            duration: 1,
            ease: "power4.out"
          }, 2);
        },
        afterOnce(data) {
          scroll.update()

          const cScrollbar = document.querySelectorAll('.c-scrollbar');
          if(cScrollbar.length > 1) {
            cScrollbar[0].remove();
          }

          cookies()
           scroll.on("scroll", (e) => {
            if (e.scroll.y >= 5) {
              var haut = document.querySelectorAll('.barre-haut')
              var bas = document.querySelectorAll('.down')
              var ptitle = document.querySelectorAll('.bloc-infos')
              if (haut.length > 0) {haut[0].classList.add("scrolled")}
              if (bas.length > 0) {bas[0].classList.add("scrolled")}
              if (ptitle.length > 0) {ptitle[0].classList.add("scrolled")}
              
             } else {
              var haut = document.querySelectorAll('.barre-haut')
              var bas = document.querySelectorAll('.down')
              var ptitle = document.querySelectorAll('.bloc-infos')
              if (haut.length > 0) {haut[0].classList.remove("scrolled")}
              if (bas.length > 0) {bas[0].classList.remove("scrolled")}
              if (ptitle.length > 0) {ptitle[0].classList.remove("scrolled")}
            }
          });

        },
        afterEnter(data) {
          scroll.init()
        },
        beforeLeave(data) {
          document.getElementsByTagName("body")[0].classList.add("non-event")
          document.getElementsByTagName("body")[0].removeAttribute('class');
          document.getElementsByTagName("body")[0].classList.add(data.next.namespace);

        },
        leave(data) {
          console.log("LEAVE");
          let done = this.async()
          let tl = gsap.timeline({
            onComplete: function () {
              done()
            }
          });

          tl

          /* CONTENU */
          .to(document.querySelectorAll('main .content'), {
            left: "0px",
            opacity: 0,
            duration: 1,
            ease: "power4.in"
          }, 0)
          /* FIN CONTENU */
          ;
        },
        afterLeave(data) {
          scroll.destroy()
        },
        afterEnter(data) {
          scroll.init()
          const target = document.querySelector('#up');
          scroll.scrollTo(target);
        },
        after(data) {
          if (data.next.url.hash && document.getElementById(data.next.url.hash)) {
            document.getElementById(data.next.url.hash).scrollIntoView({ behavior: "smooth", block: "start" });
            console.log(data.previous.url.hash)
          }

          let done = this.async()

          let tl = gsap.timeline({
            onComplete: function () {
              scroll.update();
              done()
               document.getElementsByTagName("body")[0].classList.remove("non-event")
            }
          });

          tl

          /* CONTENU */
         
          .to(document.querySelectorAll('main .content'), {
            top: "0vw",
            opacity: 1,
            duration: 1,
            ease: "power4.out"
          }, 0);
          scroll.update()

          const cScrollbar = document.querySelectorAll('.c-scrollbar');
          if (cScrollbar.length > 1) {
            cScrollbar[0].remove();
          }

          scroll.on("scroll", (e) => {
            if (e.scroll.y >= 5) {
              var haut = document.querySelectorAll('.barre-haut')
              var bas = document.querySelectorAll('.down')
              var ptitle = document.querySelectorAll('.bloc-infos')
              if (haut.length > 0) {haut[0].classList.add("scrolled")}
              if (bas.length > 0) {bas[0].classList.add("scrolled")}
              if (ptitle.length > 0) {ptitle[0].classList.add("scrolled")}
              
             } else {
              var haut = document.querySelectorAll('.barre-haut')
              var bas = document.querySelectorAll('.down')
              var ptitle = document.querySelectorAll('.bloc-infos')
              if (haut.length > 0) {haut[0].classList.remove("scrolled")}
              if (bas.length > 0) {bas[0].classList.remove("scrolled")}
              if (ptitle.length > 0) {ptitle[0].classList.remove("scrolled")}
            }
          });

          gtag('set', 'page', window.location.pathname);
          gtag('send', 'pageview');
        }
      }
    ]
  });

});
