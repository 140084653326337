import Cookies from "js-cookie";

export const cookies = function(){
  //cookies
  const cookie = Cookies.get('cookieaccept');
  const bannerCookie = document.getElementById('js-cookie-box');
  const closeCookie = document.getElementById('js-cookie-close');

  if(bannerCookie){
    if (cookie != 'yes') {
      bannerCookie.classList.add('show');
    }
    closeCookie.addEventListener('click', function (event) {
      event.preventDefault();

      bannerCookie.classList.remove('show');
      Cookies.set('cookieaccept', 'yes');

    });

    Cookies.set('cookieaccept', 'yes', { expires: 365 });
  }
}
